class HistorySticky {
  constructor(heroBlock) {
    this.heroBlock = heroBlock;
    this.heroBlockOffset = this.heroBlock.getBoundingClientRect().bottom;
    this.stickyElement = undefined;
    this.init();
  }

  init() {
    this.stickyElement = document.querySelector('[data-history-link]');

    if (this.heroBlockOffset <= 0) {
      this.addSticky();
    }

    this.addListeners();
  }

  addListeners() {
    document.addEventListener('scroll', () => {
      this.checkSticky();
      this.updateOffsetVars();
    });
  }

  checkSticky() {
    if (this.heroBlockOffset <= (this.heroBlock.getBoundingClientRect().height * -1)) {
      this.addSticky();
    } else {
      this.removeSticky();
    }
  }

  updateOffsetVars() {
    this.heroBlockOffset = this.heroBlock.getBoundingClientRect().top;
  }

  addSticky() {
    if (!this.stickyElement.classList.contains('_sticky')) {
      this.stickyElement.classList.add('_sticky');
    }
  }

  removeSticky() {
    if (this.stickyElement.classList.contains('_sticky')) {
      this.stickyElement.classList.remove('_sticky');
    }
  }
}
document.addEventListener('DOMContentLoaded', () => {
  const heroBlock = document.querySelector('[data-history-hero-block]');
  new HistorySticky(heroBlock);
});