/**
 *  Разбивает текст на спаны по строкам
 *
 *  Пример использования:
 *
 *  Ширина контейнера (наружного элемента) должна быть зафиксирована (display: block, flex: 0 0 200px, ...)
 *  иначе на разных устройствах текст будет переносится по разному.
 *
 *  Обязательно к проверке на Android / iOS!
 *
 *  <div class="text-wrap__main-text" data-need-animation data-delay="2600">
 *      <div class="text-wrap__main-text-inner"
 *           data-text-wrap
 *      >
 *         Достаточно длинный текст, чтобы его стоило разбивать на строки
 *      </div>
 *  </div>
 * */

class TextWrapCreator {
  container = undefined;
  element = undefined;
  classes = [];
  originalString = undefined;
  singleLineTestElement = undefined;
  boundedLineTestElement = undefined;
  words = [];
  line = undefined;

  constructor(selector) {
    this.element = selector;
    this.container = this.element.parentElement;
    this.classes = this.element.classList;
    this.originalString = this.element.innerHTML.trim();
    this.indent = this.element.dataset.indent ? this.element.dataset.indent : 0;
    this.container.classList.add('_text-wrap-creator-container');
    this.container.style.position = 'relative';
    this.words = this.originalString.split(' ');
    this.element.remove();
    this.line = '';
    this.initStoreString();
    this.create();
    this.eventListeners();
  }

  initStoreString() {
    this.singleLineTestElement = document.createElement('span');
    this.boundedLineTestElement = document.createElement('span');

    this.classes.forEach((className) => this.singleLineTestElement.classList.add(className));
    this.singleLineTestElement.classList.add('_text-wrap__single-line');
    this.singleLineTestElement.style.opacity = '0';
    this.singleLineTestElement.style.position = 'absolute';
    this.singleLineTestElement.style.visibility = 'hidden';
    this.singleLineTestElement.style.whiteSpace = 'nowrap';
    this.singleLineTestElement.style.display = 'inline-block';
    this.singleLineTestElement.style.width = 'auto';
    this.singleLineTestElement.style.maxWidth = 'none';

    this.classes.forEach((className) => this.boundedLineTestElement.classList.add(className));
    this.boundedLineTestElement.classList.add('_text-wrap__bound-line');
    this.boundedLineTestElement.style.opacity = '0';
    this.boundedLineTestElement.style.position = 'absolute';
    this.boundedLineTestElement.style.visibility = 'hidden';
    this.boundedLineTestElement.style.display = 'block';
    this.boundedLineTestElement.style.width = 'auto';
    this.boundedLineTestElement.style.whiteSpace = 'normal';

    this.container.append(this.boundedLineTestElement);
    this.container.append(this.singleLineTestElement);
  }

  create() {
    let currentLine = '';
    this.words.forEach((word, n) => {
      // Проверочная строка - войдет или нет?
      let nextLine = currentLine + word + ' ';
      // Удлинняем тестовую строку на 1 точку,
      // это позволяет избежать коллизий
      let testLine = currentLine + word + '.';

      // Если войдет, тогда продолжаем цикл
      // Если не войдет, тогда записываем currentLine и начинаем набирать новый span
      this.singleLineTestElement.innerHTML = testLine;
      this.boundedLineTestElement.innerHTML = testLine;

      if (this.singleLineTestElement.offsetWidth > this.boundedLineTestElement.offsetWidth) {
        // не вошло )
        this.createNewElement(currentLine);
        currentLine = word + ' ';
      } else {
        // Вошло! Продолжаем напихивать слова)
        currentLine = nextLine;
      }
    });

    if (currentLine.length) {
      this.createNewElement(currentLine);
    }

    this.singleLineTestElement.remove();
    this.boundedLineTestElement.remove();
  }

  createNewElement(testLine) {
    const newElement = document.createElement('span');
    const newElementInner = document.createElement('span');
    newElement.append(newElementInner);
    this.classes.forEach(className => newElement.classList.add(className));
    if (this.element.dataset.textInnerClass) {
      newElementInner.className = this.element.dataset.textInnerClass;
    }
    newElementInner.style.display = 'block';
    newElementInner.innerHTML = testLine;
    this.container.append(newElement);
  }

  removeAllElements() {
    while (this.container.lastChild) {
      this.container.removeChild(this.container.lastChild);
    }
  }

  eventListeners() {
    window.addEventListener('resize', () => {
      this.removeAllElements();
      this.initStoreString();
      this.create();
    });
  }
}

function init() {
  document.querySelectorAll('[data-text-wrap]:not([data-initialized="true"])')
    .forEach((item) => {
      item.dataset.initialized = 'true';
      const textWrapCreator = new TextWrapCreator(item);
    });
}

document.addEventListener('DOMContentLoaded', init);
document.addEventListener('DOMContentMutated', init);